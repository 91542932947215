<template>
  <div class="ppt_view">
    <div class="loading_box" v-show="createdLoading">
      <div class="loading_card">
        <div class="loading_icon" ref="loadingRef"></div>
        <p class="tac loading_tip">PPT生成中…</p>
      </div>
    </div>
    <iframe v-if="pptUrl" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${pptUrl}`"
            frameborder="0"></iframe>
  </div>
</template>

<script>
import { getPdfToPptByDocId } from '@/api/article.js'
import { initLottieByUrl } from '@/utils/index.js'
export default {
  props: {
    docId: Number
  },
  components: {},
  data () {
    return {
      createdLoading: false,
      inquiryNums: 0,
      waitTimeId: null,
      pptUrl: ''
    }
  },
  methods: {
    async initLottie () {

      const element = this.$refs.loadingRef
      console.log(element);

      const lottieObj = await initLottieByUrl({
        el: element,
        url: 'https://img.infox-med.com/webImg/infoxmedV2/animation/contentLoadingAnimation.json'
      })
      lottieObj.play()
    },
    clearTime () {
      if (this.waitTimeId) {
        this.inquiryNums = 0
        this.createdLoading = false
        clearInterval(this.waitTimeId)
        this.waitTimeId = null
      }
    },
    async poller () {
      if (this.inquiryNums >= 20) {
        this.clearTime()
        this.$notify({
          title: '系统提示',
          message: '服务器繁忙，请稍后重试',
          duration: 0
        })
        return
      }
      const data = await this.getPdfToPptByDocId()
      if (data) {
        this.pptUrl = data
        this.createdLoading = false
        return
      }

      this.waitTimeId = setInterval(() => {
        this.inquiryNums++
        clearInterval(this.waitTimeId)
        this.poller()
      }, 40 * 1000)


    },
    async getPdfToPptByDocId () {
      const { data } = await getPdfToPptByDocId({
        documentId: this.docId
      })
      return data
    },
    async createdPpt () {
      if (this.createdLoading || this.pptUrl) return
      this.createdLoading = true

      this.poller()
    }
  },
  mounted () {
    this.initLottie()
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.ppt_view {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
}

.loading_box {
  padding-top: 30px;
  .loading_card {
    width: 340px;
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
    background-color: #fff;

    .loading_icon {
      height: 150px;
    }
    .loading_tip {
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
