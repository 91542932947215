<template>
  <div class="page_container">
    <div class="page">
      <div class="page_content mdContent" ref="pageContentRef">
        <div v-html="info"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {


  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.page_container {
  // height: 100;
  width: 100%;
  height: calc(100vh - 33px);
  padding: 5px 10px;
  background-color: rgba(237, 237, 240, 1);
  // background-color: #f00;
}

.page {
  height: 100%;
  overflow-y: auto;
  border: 5px solid transparent;
  background-clip: content-box;
  border-image: url('https://img.infox-med.com/webImg/infoPc/public/pdfPageShadow.png')
    9 9 repeat;
  background-color: #fff;
}
.page_content {
  padding: 40px 20px 30px;
  font-size: 14px;
  line-height: 2em;
  ::v-deep img {
    max-width: 100%;
    object-fit: cover;
    margin: 20px 0;
  }
}
</style>
