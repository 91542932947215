<template>
  <div class="aiReply_content scrollbar" ref="replyContainerRef">
    <div class="aiReply mdContent" v-html="mdToHtml(aiReply)"></div>
    <AiLoading v-if="linkLoading" />
    <AiSend v-show="false" ref="aiSendRef" :fId="fId" :sId="sessionId" @send="sendMsg"
            @pushMsg="pushMsg" @pushFinish="pushFinish" @linkErr="linkErr" @setScroll="setScroll"
            @startSend="startSend" @wsClose="wsClose" />
  </div>
</template>

<script>
import AiSend from './components/send.vue'
import { mdToHtml } from '@/utils/index.js'
import AiLoading from './components/aiLoading.vue'

export default {
  props: {
    fId: String
  },
  components: {
    AiSend,
    AiLoading
  },
  data () {
    return {
      sessionId: null,
      linkLoading: false,
      aiReply: ''
    }
  },
  methods: {
    // 开始发送消息
    startSend () {
      this.linkLoading = true
    },
    wsClose () {
      this.linkLoading = false
      if (!this.aiReply) {
        this.aiReply = '服务关闭请重试'
      }
    },
    // 滚动
    setScroll (type) {
      if (!this.$refs.replyContainerRef) return
      this.$refs.replyContainerRef.scrollTop = this.$refs.replyContainerRef.scrollHeight
    },
    // 发送消息回调
    sendMsg (data) {

    },
    // 接收消息完毕
    pushFinish () {
      this.$refs.aiSendRef.closeLink()
      this.$emit('finish', this.aiReply)
    },
    // 服务器推送消息
    pushMsg (data) {
      this.sessionId = data.sessionId
      this.linkLoading = false
      if (![1, 8].includes(data.messageType)) {
        this.aiReply = '暂不支持此消息类型'
        return
      }
      this.aiReply += data.content
    },
    linkErr (error) {
      this.linkLoading = false
      if (error.reason) {
        this.aiReply = error.reason
      }
    },
    mdToHtml (str) {
      if (!str) return ''
      const regex = /【.*?†.*?】/g
      return mdToHtml(str.replace(regex, ''))
    },
    submitSend (qa) {
      this.$refs.aiSendRef.submitSend(qa)
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.aiReply_content {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.8em;
  overflow-y: auto;
  padding: 0 20px;
}
</style>
