import request from '../utils/request.js'
// 文章相关的接口

// 获取热门文章列表
export function getArticleList (data) {
  return request({
    url: '/home/hotDocument',
    data
  })
}

// 获取文章详情信息
export function getDocumentById (data) {
  return request({
    url: 'home/getDocumentById',
    data
  })
}

// 获取当前文章的相关文章
export function gainDocu (data) {
  return request({
    url: 'home/getSimilarDocument',
    data
  })
}

// 翻译内容
export function gainTran (data) {
  return request({
    url: 'home/translate',
    data
  })
}
// 翻译标题和摘要
export function translateByDocId (data) {
  return request({
    url: 'home/translateByDocumentId',
    data
  })
}

// 获取当前文章的收藏状态
export function collectStatus (data) {
  return request({
    url: '/system/getCollectStatus',
    data
  })
}

// 收藏当前文章
export function addCollect (data) {
  return request({
    url: '/system/addCollect',
    data
  })
}
// 取消收藏文章
export function callCollect (data) {
  return request({
    url: '/system/removeCollect',
    data
  })
}

// 普通搜索
export function search (data) {
  return request({
    url: 'home/search',
    data,
    headers: {
      hideLoading: true
    }
  })
}

// 高级搜索
export function gainHighSearch (data) {
  return request({
    url: '/home/advancedSearch',
    data,
    headers: {
      hideLoading: true
    }
  })
}

// 获取高级搜索的字段
export function gainHighField (data) {
  return request({
    url: '/home/getAdvanceSearchField',
    data,
    headers: {
      hideLoading: true
    }
  })
}

// 获取pdf文件的url地址
export function getPdfUrl (data) {
  return request({
    url: '/home/getPdfFileLinkView',
    data
  })
}
// 读取pdf文件内容
// export function getPdfBlob (url) {
//   return axios({
//     url: url,
//     method: 'GET',
//     responseType: 'blob'
//   })
// }
// 获取PDF翻译状态
export function getPdfZhStatus (data) {
  return request({
    url: '/home/getDocumentTextStatusById',
    data,
    headers: {
      hideLoading: true
    }
  })
}
// 获取用户当前PDF的翻译状态和翻译次数
export function getUserTranslatePdfInfo (data) {
  return request({
    url: '/home/getDocumentTranslateById',
    data,
    headers: {
      hideLoading: true
    }
  })
}
// 获取PDF中文译文
export function getPdfZh (data) {
  return request({
    url: 'home/getChinesePdf',
    data,
    headers: {
      hideLoading: true

    }
  })
}

export function getTranslatePdfInfo (data) {
  return request({
    url: '/home/getChinesePdfPro',
    data,
    headers: {
      hideLoading: true

    }
  })
}

export function getTranslatePdfByText (data) {
  return request({
    url: 'home/getChinesePdf',
    data,
    headers: {
      hideLoading: true

    }
  })
}

export function getTranslatePdfByMarkdown (data) {
  return request({
    url: 'home/getChinesePdfMarkdown',
    data,
    headers: {
      hideLoading: true

    }
  })
}
export function getTranslatePdfByMdState (data) {
  return request({
    url: '/home/getChinesePdfMarkdownTextStatus',
    data,
    headers: {
      hideLoading: true
    }
  })
}

export function getPdfToPptByDocId (data) {
  return request({
    url: 'home/getGeneratedPpt',
    data,
    headers: {
      hideLoading: true
    }
  })
}

export function getPdfZhStatusPro (data) {
  return request({
    url: '/home/getChinesePdfProTextStatus',
    data,
    headers: {
      hideLoading: true
    }
  })
}
// 下载PDF文件
export function downloadPdf (data) {
  return request({
    url: '/home/getPdfFileLink',
    data
  })
}

// 获取指南 （停用）
export function getGuideList (data) {
  return request({
    url: '/home/getGuideList',
    data
  })
}

// 获取文献列表
export function getDocumentList (data) {
  return request({
    url: '/home/getDocumentList',
    data
  })
}

// 获取文章作者和作者的通讯地址
export function getAuthorAddr (data) {
  return request({
    url: '/home/getAuthorAddrById',
    data
  })
}

// 获取文章作者和作者的通讯地址-中文
export function getAuthorAddrZh (data) {
  return request({
    url: 'home/getAuthorAddrCnById',
    data
  })
}

// 获取文献的引用格式
export function getCitationFormat (data) {
  return request({
    url: '/home/getCitationFormat',
    data
  })
}

// 获取引用该文献列表
export function getCitedByList (data) {
  return request({
    url: '/home/getCitedByList',
    data
  })
}

// 获取参考的文献列表
export function getReferenceList (data) {
  return request({
    url: '/home/getReferenceList',
    data
  })
}
// 获取文献图表
export function getFiguresList (data) {
  return request({
    url: '/home/getFigures',
    data
  })
}

// 获取热门搜索列表
export function getHotSearchList () {
  return request({
    url: '/home/hotSearch'
  })
}

/**
 * 撤稿通知查看原文
 * @param {*}
 *  pmid: 当前文献pmid
 *  type: 0查询撤稿的本稿  1撤稿通知
 * @returns
 */
export function getRetractInfo (data) {
  return request({
    url: '/home/getRetractInfo',
    data
  })
}

/**
 * 统计用户查看文献
 * category: 1.文档 2.资讯 3.学院视频 4.学院文档 5.学院直播 6.学院录播
 * itemId: documentId
 */
export function putViewcount (data) {
  return request({
    url: '/system/putViewcount',
    data
  })
}

// 批量获取引用格式
export function getFormatList (data) {
  return request({
    url: '/home/batchExportCitationFormat',
    data
  })
}

// 获取用户搜索自定义文献类型
export function getUserDocTypes () {
  return request({
    url: '/home/getCustomPublishTypes',
    headers: {
      hideLoading: true
    }
  })
}

// 更新用户自定义文献类型
export function updataUserDocTypes (data) {
  return request({
    url: '/home/uploadCustomPublishTypes',
    data
  })
}

// 获取所有文献类型
export function getSearchDocTypes () {
  return request({
    url: '/home/getAllPublishTypes',
    headers: {
      hideLoading: true
    }
  })
}

// 根据pmid获取详情
export function getDocumentByPmid (data) {
  return request({
    url: 'home/getDocumentByPmid',
    data
  })
}

// 导出其他格式文件
// export function exportDocFile (data) {
//   return request({
//     method: 'GET',
//     url: '/home/getDocumentMetaInfoFile',
//     data
//   })
// }
