<template>
  <div class="" v-if="userId && isShowAiReply">
    <div class="ai_docList" v-if="docList.length">
      <div class="docList_card_top">
        <div class="title">AI 总结</div>
        <div>总结 <span class="highlight">{{docList.length}} 篇</span>相关文献</div>
      </div>
      <div class="dicList">
        <div class="doc_item" v-for="item in docList" :key="item.id" @click="toDocDetails(item)">
          <div class="doc_title row_1">{{item.docTitle}}</div>
          <div class="item_info row_1">来源期刊：{{item.docSourceJournal}}</div>
          <div class="item_info row_1">IF：{{item.docIf}}</div>
          <div class="item_info row_1">发表时间：{{$initTime(item.docPublishTime)}}</div>
        </div>
      </div>
    </div>

    <div class="ai_summarize" ref="aiAnswerRef">
      <div class="loading_box" v-show="loading && !aiAnswer">
        <AiLoading />
        <span class="loading_tip">{{stateTip}}</span>
      </div>
      <div :class="{'replay_content': loading}" v-html="mdToHtml()"></div>
    </div>

  </div>
</template>

<script>
import { sseApi, stopSse } from '@/api/sseApi.js'
import { mapGetters } from 'vuex'
import { mdToHtml, blankPagePar } from '@/utils/index.js'
import AiLoading from '@/components/aiSendMsg/components/aiLoading.vue'
import { getMyRead, setMyRead } from '@/utils/storage.js'

export default {
  props: {
    keyWords: String
  },
  components: {

    AiLoading
  },
  data () {
    return {
      aiAnswer: '',
      loading: false,
      isShowAiReply: false,
      docList: [],
      taskId: '',
      conversationId: '',
      stateTip: '',
      ssekey: 'app-foKJ5oK7WgmSQHR539HscUjC'
    }
  },
  watch: {
    keyWords (newVal, oldVal) {
      // this.loading = true
      this.aiAnswer = ''
      this.getAiContent()
    }
  },
  methods: {
    toDocDetails (item) {
      if (!this.$store.getters.userId) return this.$login()
      let arr = JSON.parse(getMyRead() || '[]')
      arr.unshift({
        id: item.id
      })
      arr = arr.map(items => {
        return JSON.stringify(items)
      })
      arr = [...new Set(arr)]
      arr = arr.map(it => {
        return JSON.parse(it)
      })
      setMyRead(arr)
      this.$myCount({
        name: 'PC-文献/指南访问',
        itemId: item.id
      })
      blankPagePar('articleDetails', { id: item.id })
    },
    setDocList (msg) {
      if (msg.observation) {
        const observation = JSON.parse(msg.observation)
        if (observation.getDocumentWorkflow) {
          const resList = observation.getDocumentWorkflow.split('tool response:')
          const documentWorkflow = JSON.parse(JSON.parse(resList[0]).body)
          this.docList = documentWorkflow.data
        }
      }
    },
    setScroll () {
      const aiAnswerRef = this.$refs.aiAnswerRef
      if (!aiAnswerRef) return
      aiAnswerRef.scrollTop = aiAnswerRef.scrollHeight
    },
    mdToHtml () {
      return mdToHtml(this.aiAnswer)
    },
    formatMsg (msg) {
      this.taskId = msg.task_id
      this.stateTip = 'AI 总结中'
      this.conversationId = msg.conversation_id
      if (msg.event === 'agent_message') {
        this.aiAnswer += msg.answer
        this.setScroll()
      }
      if (msg.event === 'agent_thought') {
        this.setDocList(msg)
      }
    },

    async getAiContent () {
      if (!this.userId) return
      if (this.taskId) {
        await stopSse({
          url: `/chat-messages/${this.taskId}/stop`,
          key: this.ssekey,
          data: {
            user: this.userId
          }
        })
      }
      const data = {
        inputs: {},
        query: this.keyWords, // '肺癌', 'catecholamine;按if值排序',
        response_mode: 'streaming',
        conversation_id: '',
        user: this.userId
      }
      this.stateTip = 'AI 正在链接'
      this.isShowAiReply = true
      this.loading = true
      this.docList = []
      sseApi({
        key: this.ssekey,
        url: '/chat-messages',
        data,
        message: this.formatMsg,
        open: () => {
          this.isShowAiReply = true
          this.loading = true
          this.aiAnswer = ''
          this.stateTip = 'AI 搜索中'
          this.docList = []
        },
        close: () => {
          this.loading = false
        }
      })
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.ai_summarize {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
  line-height: 1.8em;
  font-size: 14px;
}
.loading_box {
  display: flex;
  align-items: center;
  gap: 10px;
  .loading_tip {
    position: relative;
    top: 1px;
  }
}

.replay_content > :last-child {
  &::after {
    display: inline-block;
    content: '';
    width: 2px;
    height: 1em;
    background-color: #000;
    vertical-align: middle;
    margin-left: 3px;
    animation: blink 0.7s step-end infinite;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.ai_docList {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  .docList_card_top {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 5px;
    .title {
      font-size: 15px;
      font-weight: 600;
    }
  }
  .dicList {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    overflow-x: auto;
    .doc_item {
      flex-shrink: 0;
      width: 245px;
      padding: 10px;
      border-radius: 4px;
      background-color: #f5f7fb;
      .doc_title {
        color: #000;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .item_info {
        line-height: 1.8em;
        font-size: 12px;
        color: #666;
      }
    }
  }
}
</style>
