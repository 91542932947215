<template>
  <div class="">
    <div class="translate_all flc" ref="translate">
      <span v-show="pdfTranslateInfo.traCount || isLoading" id="translateIcon"></span>
      <span class="fs12" v-show="isLoading">翻译中，请勿关闭当前页面</span>
      <el-dropdown trigger="click" @command="translateClick" v-show="!isLoading">
        <span class="el-dropdown-link">
          <i class="iconfont fs20 icon-translate"
             v-if="!pdfTranslateInfo.traCount && !isLoading"></i>
          全文翻译<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :icon="item.icon" v-for="item in translateTypes" :command="item.val"
                            :key="item.val">{{item.label}}</el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <DownloadApp :show.sync="isShow" />
    <MyDialog ref="translateDialog" :isShow.sync="showTransWarn" title="规则说明" :position="options"
              width="550px" height="240px">
      <div class="rule_content">
        <p v-for="(warn,i) in ruleList" :key="i" v-html="warn.text"></p>
      </div>
      <!-- 免费次数翻译 -->
      <div class="options" v-if="(10 - (pdfTranslateInfo.traCountToday || 0)) > 0">
        <span class="residue_num">当前剩余次数：
          <i>{{ 10 - (pdfTranslateInfo.traCountToday || 0)}}</i>
          次</span>
        <span class="start_trans_btn hand"
              :class="{'disable_btn':pdfTranslateInfo.traCountToday === 10}"
              @click="startTranslate">开始翻译</span>
      </div>
      <!-- 积分兑换 -->
      <div v-else class="options integral_option">
        <div class="integral_tip">
          <p>今日已翻译了3篇，请明天再来或通过消耗 66 积分翻译原文~</p>
          <p class="secondary mt5">当前账户剩余积分：<span>{{$store.getters.userInfo.signScore}}</span></p>
        </div>
        <span v-if="$store.getters.userInfo.signScore - 66 >=0" class="integralExchange_btn hand"
              @click="integralExchange">
          <span class="btn_text">积分兑换</span>
        </span>
        <span v-else @click="openDownloadApp" class="integralExchange_btn hand earn_btn">
          <span class="btn_text earn_btn_text">赚取积分</span>
        </span>
      </div>
    </MyDialog>

    <DownloadApp :show.sync="isShowPointsTipDialog">
      <template #title>
        <div class="pointsTipDialogTitle">
          前往<span class="title_text">[APP-我的-积分中心]</span>
          赚取积分
        </div>
      </template>
      <template #foot>
        <div>
          <p class="fs16">扫码下载 Info X Med APP</p>
        </div>
      </template>
    </DownloadApp>
  </div>
</template>

<script>
import MyDialog from '@c/myDialog/newIndex.vue'
import { getPdfZhStatusPro, getUserTranslatePdfInfo, getTranslatePdfInfo, getTranslatePdfByText, getTranslatePdfByMarkdown, getTranslatePdfByMdState } from '@/api/article.js'

import DownloadApp from '@c/downloadAppDlalog/index.vue'
import { mdToHtml, initLottieByUrl } from '@/utils/index.js'

export default {
  props: {
    pdfPageTotal: {
      type: Number,
      default: 0
    },
    documentId: {
      type: String,
      default: ''
    },
    translateType: Number
  },
  components: {
    DownloadApp,
    MyDialog,
    DownloadApp
  },
  data () {
    return {
      translateTypes: [
        {
          label: '原版式',
          icon: 'el-icon-notebook-2',
          val: 1
        }, {
          label: '图文版',
          icon: 'el-icon-postcard',
          val: 2
        }, {
          label: '纯文本',
          icon: 'el-icon-document',
          val: 3
        }
      ],
      ruleList: [
        {
          text: '1、原文翻译需要等待，<span class="warn_highlight">翻译过程中请勿关闭当前页</span>'
        }, {
          text: '2、全文翻译功能目前属于内测阶段，每天支持翻译10次。'
        }
        , {
          text: '3、全文翻译次数使用完以后，支持消耗积分翻译全文。'
        }
      ],
      isShow: false,
      options: {},
      showTransWarn: false,
      ws: {},
      isLoading: false,
      waitTimeId: null,
      inquiryNums: 0,
      anim: {},
      pdfTranslateInfo: {},
      isShowPointsTipDialog: false
    }
  },
  methods: {
    // 获取纯文本翻译
    async translateByText (type, fn) {
      try {
        this.isLoading = true
        let body = { documentId: this.documentId }
        if (type) {
          body.useSignScore = 1
        }
        const res = await getTranslatePdfByText(body)
        fn && fn()
        if (res.code === 0) {
          this.isLoading = false
          this.showPdfZh(res.data)
        } else {
          this.getPdfTranslateStatus()
        }
      } catch (error) {
        this.isLoading = false
        if (error === '积分不足') {
          this.isShowPointsTipDialog = true
          this.$refs.translateDialog.closeDialog()
        } else {
          this.$message.error(error)
        }
      }

    },
    // 获取markdown格式翻译
    async translateByMd (type, fn) {
      try {
        this.isLoading = true
        let body = { documentId: this.documentId }
        if (type) {
          body.useSignScore = 1
        }
        const { data } = await getTranslatePdfByMarkdown(body)
        fn && fn()
        if (data) {
          this.isLoading = false
          this.showPdfZh(mdToHtml(data))
        } else {
          this.getPdfTranslateStatus()
        }
      } catch (error) {
        this.isLoading = false
        if (error === '积分不足') {
          this.isShowPointsTipDialog = true
          this.$refs.translateDialog.closeDialog()
        } else {
          this.$message.error(error)
        }
      }

    },
    // 根据翻译类型调用翻译服务
    beforeTranslate (type) {
      const transType = type || this.translateType
      switch (transType) {
        case 1:
          this.translatePdfServe()
          break;
        case 2:
          this.translateByMd()
          break;
        case 3:
          this.translateByText()
          break;
      }
    },
    translateClick (type) {
      this.$emit('update:translateType', type)
      if (!this.$store.getters.userInfo.isHy) return (this.isShow = true)
      if (this.isLoading) return
      if (!this.pdfTranslateInfo.traCount) {
        this.showTransWarn = true
        return
      }
      this.beforeTranslate(type)
    },
    openDownloadApp () {
      this.isShowPointsTipDialog = true
      this.$refs.translateDialog.closeDialog()
    },
    // 积分兑换调用翻译服务
    integralExchange () {
      this.$refs.translateDialog.closeDialog()
      const serveMap = {
        1: 'translatePdfServe',
        2: 'translateByMd',
        3: 'translateByText'
      }
      this[serveMap[this.translateType]](true, () => {
        this.$message.success('积分兑换成功，正在翻译请稍后')
      })
    },
    async getUserTranslatePdfInfo () {
      const { data } = await getUserTranslatePdfInfo({
        documentId: this.documentId
      })
      this.pdfTranslateInfo = data
    },
    async initLottie () {
      this.anim = await initLottieByUrl({
        el: document.querySelector('#translateIcon'),
        url: 'https://img.infox-med.com/webImg/public/animIcons/translateIcon/data.json'
      })
    },
    showPdfZh (data) {
      this.getUserTranslatePdfInfo()
      this.$emit('showPdfZh', data)
    },
    clearTime () {
      if (this.waitTimeId) {
        this.inquiryNums = 0
        this.isLoading = false
        clearInterval(this.waitTimeId)
      }
    },
    // 翻译服务轮询器
    async getPdfTranslateStatus () {
      if (this.inquiryNums >= 20) {
        this.clearTime()
        this.$notify({
          title: '系统提示',
          message: '服务器繁忙，请稍后重试',
          duration: 0
        })
        return
      }
      const apiMap = {
        1: getPdfZhStatusPro,
        2: getTranslatePdfByMdState,
        3: getTranslatePdfByText
      }

      const { data } = await apiMap[this.translateType]({
        documentId: this.documentId
      })
      if ((data === 2 && [1, 2].includes(this.translateType)) || (data && this.translateType === 3)) {
        this.clearTime()
        this.beforeTranslate()
        return
      }
      const time = this.translateType === 1 ? 3 : 20
      this.waitTimeId = setInterval(() => {
        this.inquiryNums++
        clearInterval(this.waitTimeId)
        this.getPdfTranslateStatus()
      }, time * 1000)
    },
    async translatePdfServe (type = false, fn) {
      try {
        this.isLoading = true
        let body = { documentId: this.documentId }
        if (type) {
          body.useSignScore = 1
        }
        const res = await getTranslatePdfInfo(body)
        fn && fn()
        if (res.code === 0) {
          this.isLoading = false
          this.showPdfZh()
        } else {
          this.getPdfTranslateStatus()
        }
      } catch (error) {
        this.isLoading = false
        if (error === '积分不足') {
          this.isShowPointsTipDialog = true
          this.$refs.translateDialog.closeDialog()
        } else {
          this.$message.error(error)
        }
      }
    },
    beforeunload (event) {
      var confirmationMessage = 'PDF正在翻译中, 确定要离开此页面吗？';
      (event || window.event).returnValue = confirmationMessage
      return confirmationMessage
    },

    startTranslate () {
      if (this.pdfTranslateInfo.traCountToday === 10) return
      this.$refs.translateDialog.closeDialog()
      this.beforeTranslate()
    },
    translatePdfClick () {
      // this.showPdfZh(this.pdfZhInfo)
      // return
      if (!this.$store.getters.userInfo.isHy) return (this.isShow = true)
      if (this.isLoading) return
      if (this.pdfTranslateInfo.traCount) {
        return this.translatePdfServe()
      } else {
        this.showTransWarn = true
      }
    }
  },
  mounted () {
    const box = this.$refs?.translate?.getBoundingClientRect()
    this.options = {
      x: box.x + 20,
      y: box.y + 10
    }
    this.initLottie()
  },
  watch: {
    isLoading (newVal) {
      if (newVal) {
        window.addEventListener('beforeunload', this.beforeunload)
        this.anim.play && this.anim.play()
      } else {
        window.removeEventListener('beforeunload', this.beforeunload)
        this.anim.stop && this.anim.stop()
      }
    }
  },
  created () {
    this.getUserTranslatePdfInfo()
  }
}
</script>

<style lang="less" scoped>
.translate_all {
  padding: 0 5px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
  font-size: 18px;
  margin-right: 3px;
  color: #000;
  background-color: #f9f9fa;
  &:hover {
    background-color: #dddedf;
  }
}
.rule_content {
  padding: 20px;
  font-size: 15px;
  line-height: 2em;

  /deep/.warn_highlight {
    color: #4b639f;
  }
}
.options {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .residue_num {
    font-size: 14px;
    i {
      font-weight: 600;
      color: #4b639f;
    }
  }
  .start_trans_btn {
    padding: 2px 10px;
    font-size: 15px;
    border: 1px solid #4b639f;
    color: #4b639f;
    border-radius: 4px;
    &:hover {
      color: #fff;
      background-color: #4b639f;
    }
  }
  .disable_btn:hover {
    color: #4b639f;
    background-color: transparent;
  }
}

.integral_tip {
  font-size: 15px;
  background: linear-gradient(to top right, #fe9317, #ff5027);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}
.secondary {
  font-size: 12px;
  // color: #666;
}

.integralExchange_btn {
  position: relative;
  font-size: 15px;
  width: 82px;
  height: 27px;
  // border: 1px solid #4b639f;
  // color: #4b639f;
  border-radius: 4px;
  background: linear-gradient(to top right, #fe9317, #ff5027);
  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: #fff;
    border-radius: 4px;
  }
  &:hover {
    &::after {
      background: linear-gradient(to top right, #fe9317, #ff5027);
    }
    .btn_text {
      color: #fff;
      font-weight: 600;
    }
  }
  .btn_text {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    background: linear-gradient(to top right, #fe9317, #ff5027);
    background-clip: text;
    color: transparent;
    z-index: 99;
  }
}
.earn_btn {
  background: linear-gradient(to top right, #a4d7ff, #206bed);
  &:hover {
    &::after {
      background: linear-gradient(to top right, #a4d7ff, #206bed);
    }
  }
  .earn_btn_text {
    background: linear-gradient(to top right, #a4d7ff, #206bed);
    background-clip: text;
    color: transparent;
  }
}

.translate_icon_box {
  position: absolute;
  top: 0;
  right: 200px;
}
#translateIcon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.pointsTipDialogTitle {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  .title_text {
    background: linear-gradient(to top right, #a4d7ff, #206bed);
    background-clip: text;
    color: transparent;
  }
}
</style>
