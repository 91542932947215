<template>
  <div>
    <div class="top_line"
         :style="{ background: `linear-gradient(to top, ${bg} 30%  ,transparent )` }"></div>
    <div class="send_container" :style="{ backgroundColor: bg }">
      <div class="send_content">
        <el-input ref="sendInputRef" v-model="qa" style="width: 100%" :disabled="loading"
                  :autosize="{ minRows: 1, maxRows: 6 }" type="textarea"
                  :placeholder="loading ? 'AI 回复中…' : placeholder" resize="none"
                  @keydown.enter="keyDownEnter" />

        <div class="options">
          <div class="send_box">
            <img v-if="!loading"
                 src="https://img.infox-med.com/webImg/infoxmedV2/icons/sendIcon.png" alt=""
                 @click="sendBtnClick" class="send_btn">
            <img v-else src="https://img.infox-med.com/webImg/infoxmedV2/icons/sending.png" alt=""
                 class="sending">
          </div>
        </div>
      </div>

      <p class="declare">以上内容均由AI生成, 仅供参考和借鉴</p>
    </div>
  </div>
</template>

<script>
import { uuid, isDataType } from '@/utils/index.js'

export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    fId: String,
    sId: String,
    beforSendData: Function,
    linkType: {
      type: String,
      default: '5'
    },
    bg: {
      type: String,
      default: '#f5f7fb'
    }
  },
  components: {},
  data () {
    return {
      qa: '',
      ws: {},
      loading: false,
      isOpenLinkState: false,
      waitTimeId: null,
      occupyTimeId: null
    }
  },
  methods: {
    setLoading (state) {
      this.loading = state
      this.$store.commit('aiLink/setLinkLoading', state)
    },
    initWsLink (fn) {
      if (typeof (WebSocket) === 'undefined') {
        this.$message.warning('不支持WebSocket！')
      } else {
        const sessionId = uuid()
        const tk = encodeURIComponent(this.$store.getters.token)
        const infoLink = `wss://api.infox-med.com/ws/endpoint2?token=${tk}&sessionId=${sessionId}&connectGreeting=0`
        this.ws = new WebSocket(infoLink)
        this.ws.onopen = () => this.waOpen(fn)
        this.ws.onmessage = msg => this.wsMsg(msg)
        this.ws.onerror = err => this.wsErr(err)
        this.ws.onclose = err => this.wsClose(err)
      }
    },
    waOpen (fn) {
      this.occupyTime()
      this.isOpenLinkState = true
      fn && fn()
    },
    pushFinich () {
      this.setLoading(false)
      this.$emit('pushFinish')
      this.setScroll()
      this.occupyTime()
      this.$refs.sendInputRef.focus()
    },
    wsMsg (msgEvent) {
      if (msgEvent.data === '连接成功') return
      const msgObj = JSON.parse(msgEvent.data || {})
      const data = JSON.parse(msgObj.message)
      this.clearWaitTime()
      if (data.messageType === 8) {
        if (data.content === '[stop]') {
          this.pushFinich()
        } else {
          this.$emit('pushMsg', data, msgObj)
          this.waitTime()
          this.setScroll()
        }
      } else {
        this.$emit('pushMsg', data, msgObj)
        this.pushFinich()
      }
    },
    setScroll (type = 'push') {
      this.$nextTick(() => {
        this.$emit('setScroll', type)
      })
    },
    wsErr (err) {
      this.$emit('linkErr', err)
    },
    wsClose (err) {
      console.log(err, 'linkClose')
      this.clearOccupytTime()
      this.clearWaitTime()
      this.isOpenLinkState = false
      this.setLoading(false)
      this.$emit('wsClose')
    },
    linkWs (fn) {
      this.initWsLink(fn)
    },
    sendBtnClick () {
      const reg = /^\s+$/g
      if (!this.qa || reg.test(this.qa)) {
        this.qa = ''
        return this.$message.warning('请输入有效内容')
      }
      this.submitSend(this.qa)
    },
    submitSend (text) {
      if (this.loading) return
      if (!this.isOpenLinkState) {
        return this.linkWs(() => {
          this.sendMsg(text)
        })
      }
      this.sendMsg(text)
    },
    setSendData (submitInfo) {
      const isString = isDataType(submitInfo, 'string')
      let data = {
        functionId: this.fId,
        messageType: 1,
        ts: new Date().getTime(),
        content: '',
        attachment: {},
        sessionId: this.sId || null,
        isNew: this.sId ? 0 : 1
      }
      if (isString) {
        data.content = submitInfo
      } else {
        data = { ...data, ...submitInfo }
      }

      if (this.fId === '213') {
        data.attachment.template = 0
      }

      if (this.beforSendData) {
        data = this.beforSendData(data)
      }
      return data
    },
    sendMsg (submitInfo) {
      this.setLoading(true)
      this.$emit('startSend')
      const data = this.setSendData(submitInfo)
      this.$emit('send', data)
      this.clearOccupytTime()
      this.waitTime()
      this.setScroll('send')
      this.qa = ''
      const body = {
        message: data,
        createdTime: new Date().getTime(),
        type: this.linkType
      }
      this.ws.send(JSON.stringify(body))
    },
    closeLink () {
      this.ws.close()
    },
    // 服务超时时长
    waitTime () {
      this.waitTimeId = setTimeout(() => {
        this.closeLink()
        this.$emit('linkErr', { reason: '响应超时' })
      }, 1500 * 60)
    },
    // 清除等待定时器
    clearWaitTime () {
      if (this.waitTimeId) {
        clearTimeout(this.waitTimeId)
        this.waitTimeId = null
      }
    },
    // 待机时长
    occupyTime () {
      this.occupyTimeId = setTimeout(() => {
        this.closeLink()
      }, 5000 * 60)
    },

    clearOccupytTime () {
      if (this.occupyTimeId) {
        clearTimeout(this.occupyTimeId)
        this.occupyTimeId = null
      }
    },
    // 回车事件
    keyDownEnter () {
      this.$refs.sendInputRef.blur()
      this.sendBtnClick()
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.send_container {
  padding-bottom: 20px;
}

.top_line {
  width: 100%;
  height: 30px;
}

.send_content {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  align-items: end;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #000000;
}

::v-deep .el-textarea__inner {
  font-size: 14px;
  border: 0;
  box-shadow: 0 0 0 0 transparent !important;
  background-color: transparent !important;
}

.send_btn {
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.sending {
  width: 32px;
  height: 32px;
  cursor: not-allowed;
  opacity: 0.7;
}

.declare {
  color: #5e6772;
  text-align: center;
  padding-top: 15px;
}
</style>
