import { render, staticRenderFns } from "./aiChat.vue?vue&type=template&id=07cee654&scoped=true&"
import script from "./aiChat.vue?vue&type=script&lang=js&"
export * from "./aiChat.vue?vue&type=script&lang=js&"
import style0 from "./aiChat.vue?vue&type=style&index=0&id=07cee654&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cee654",
  null
  
)

export default component.exports