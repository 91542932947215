<template>
  <div id="aiLoading" ref="aiLoading"></div>
</template>

<script>
import { initLottieByUrl } from '@/utils/index.js'

export default {
  props: {},
  components: {},
  data () {
    return {
    }
  },
  methods: {
    async initLottie () {
      const element = this.$refs.aiLoading
      const lottieObj = await initLottieByUrl({
        el: element,
        url: 'https://img.infox-med.com/webImg/infoxmedV2/icons/aiLinkLoading.json'
      })
      lottieObj.setSpeed(1.5)
      lottieObj.play()
    }
  },
  mounted () {
    this.initLottie()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
#aiLoading {
  width: 20px;
  height: 20px;
  margin-left: 30px;
}
</style>
