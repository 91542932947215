import axios from 'axios'
export const fetchEventSource = (url, options) => {
  fetch(url, options).then(resp => {
    if (resp.status === 200) {
      options.onopen && options.onopen()
      return resp.body
    }
  }).then(rb => {
    const reader = rb.getReader()
    const push = () => {
      return reader.read().then(({ done, value }) => {
        if (done) {
          options.onclose && options.onclose()
          return
        }
        //
        options.onmessage && options.onmessage(new TextDecoder().decode(value, { stream: true }))
        return push()
      })
    }
    return push()
  }).catch((e) => {
    options.error && options.error(e)
  })
}

const baseUrl = 'https://dify.infox-med.com/v1'

export const sseApi = (config) => {
  let buffer = ''
  fetchEventSource(`${baseUrl}${config.url}`, {
    method: 'POST',
    body: JSON.stringify(config.data),

    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.key}`
    },
    onopen: () => {
      console.log('连接sse成功')
      config.open && config.open()
    },
    onclose: () => {
      console.log('sse连接关闭')
      config.close && config.close()
    },
    error: (e) => {
      console.log(e)
    },
    onmessage: (data) => {
      buffer += data
      const prefix = 'data: '
      const boundary = '\n\n' // SSE 消息分隔符
      let index
      while ((index = buffer.indexOf(boundary)) !== -1) {
        const completeMessage = buffer.slice(0, index)
        buffer = buffer.slice(index + boundary.length)
        if (completeMessage === 'event: ping') continue
        if (!completeMessage.startsWith(prefix)) continue
        const jsonStr = completeMessage.substring(5).trim()
        const eventKey = ['"event": "agent_thought"', '"event": "agent_message"', '"event": "message_end"']
        const isMsg = eventKey.some(key => completeMessage.indexOf(key) !== -1)
        if (isMsg) {
          config.message(JSON.parse(jsonStr))
        }
      }

      // if (buffer.indexOf('\n') === -1) return
      // const lines = buffer.split('\n')
      // buffer = ''
      // const prefix = 'data: '
      // lines.filter(item => item).forEach(item => {
      //   if (item === 'event: ping') return
      //   if (!buffer.startsWith(prefix)) return
      //   const jsonStr = item.substring(5).trim()

      //   const eventKey = ['"event": "agent_thought"', '"event": "agent_message"', '"event": "message_end"']
      //   const isMsg = eventKey.some(key => item.indexOf(key) !== -1)
      //   if (isMsg) {
      //     config.message(JSON.parse(jsonStr))
      //   }
      // })
    }
  })
}

export const stopSse = async (config) => {
  const res = await axios.post(`${baseUrl}${config.url}`, config.data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${config.key}`
      }
    }
  )
  return res.data
}
