<template>
  <div class="page_container">
    <div class="page">
      <div class="page_content" ref="pageContentRef">
        <div class="title" v-html="info.titleZh">
        </div>
        <div class="section" v-for="(item,i) in info.sections" :key="i">
          <div class="section_title" v-html="item.headingZh"></div>
          <div class="text" v-html="item.textZh"></div>
        </div>
      </div>
    </div>

    <div class="export_page">

    </div>

  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    exportLoading: Boolean
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    async createPDF (el, name) {
      el.style.overflow = 'visible';
      const canvas = await html2canvas(el, {
        allowTaint: true,//允许跨域
        height: el.scrollHeight,//
        width: el.scrollWidth,//为了使横向滚动条的内容全部展示，这里必须指定
        background: "#FFFFFF",//如果指定的div没有设置背景色会默认成黑色
        scale: 3
      })

      var contentWidth = canvas.width;
      var contentHeight = canvas.height;

      //一页pdf显示html页面生成的canvas高度;
      var pageHeight = contentWidth / 595.28 * 841.89;
      //未生成pdf的html页面高度
      var leftHeight = contentHeight;
      //pdf页面偏移
      var position = 0;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      var imgWidth = 555.28;
      var imgHeight = 555.28 / contentWidth * contentHeight;

      var pageData = canvas.toDataURL('image/jpeg');

      var pdf = new jsPDF('', 'pt', 'a4');
      //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
          leftHeight -= pageHeight;
          position -= 841.89;
          //避免添加空白页
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }

      pdf.save(name + '.pdf');
      el.style.overflow = 'auto';
      this.$emit('update:exportLoading', false)
    },
    export () {
      this.$emit('update:exportLoading', true)
      const el = this.$refs.pageContentRef
      this.createPDF(el, this.info.titleZh)
    }
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.page_container {
  // height: 100;
  width: 100%;
  height: calc(100vh - 33px);
  padding: 5px 10px;
  background-color: rgba(237, 237, 240, 1);
  // background-color: #f00;
}

.page {
  height: 100%;
  overflow-y: auto;
  border: 5px solid transparent;
  background-clip: content-box;
  border-image: url('https://img.infox-med.com/webImg/infoPc/public/pdfPageShadow.png')
    9 9 repeat;
  background-color: #fff;
}
.page_content {
  padding: 40px 20px 30px;
  font-size: 14px;
  line-height: 2em;
}
.title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.section {
  .section_title {
    font-size: 16px;
    margin: 20px 0 10px;
    font-weight: 600;
  }
}
</style>
