import router from '@/router/index.js'
import moment from 'moment'
import axios from 'axios'
import { Message, MessageBox, Notification } from 'element-ui'
import { ElMapExportTable } from 'table-excel'
import { userCount } from '@/api/user'
import { marked } from '@/utils/marked.esm.js'
import lottie from 'lottie-web'

export const initLottieByUrl = async (config) => {
  const { data } = await axios.get(config.url)
  return initLottieByData({
    el: config.el,
    data
  })
}

export const initLottieByData = (config) => {
  return lottie.loadAnimation({
    container: config.el,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    name: 'translateIcon',
    animationData: config.data
  })
}

// 得到uuid
export function uuid () {
  var tempurl = URL.createObjectURL(new Blob())
  var uuid = tempurl.toString()// blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
  URL.revokeObjectURL(tempurl)
  return uuid.slice(uuid.lastIndexOf('/') + 1)
}
// 路由跳转 query  ID
export function pageTurn (url, id) {
  const routeUrl = router.resolve({
    path: `/${url}`,
    query: { id }
  })
  window.open(routeUrl.href, '_blank')
}
//  路由跳转 params  ID
export function pagePar (url, id) {
  const routeUrl = router.resolve({
    path: `/${url}`,
    params: { id }
  })
  window.open(routeUrl.href, '_blank')
}

// 路由跳转  query  自定义
export function blankPagePar (url, query) {
  const routeUrl = router.resolve({
    path: `/${url}`,
    query
  })
  window.open(routeUrl.href, '_blank')
}

// 处理期刊总数
export function JournalDocumentSum (count) {
  return count.split(' ').map(item => item.split(':')[1]).reduce((sum, item) => {
    return sum + Number(item)
  }, 0)
}
// 处理时间格式
export function initTime (tiem, format = 'yyyy-MM-DD') {
  if (!tiem) return ''
  return moment(tiem).format(format)
}

// 消息通知
const messageFn = (type, msg) => {
  Message({
    type,
    message: msg,
    offset: 55
  })
}
export const myMessage = {
  warning: (msg) => messageFn('warning', msg),
  success: (msg) => messageFn('success', msg),
  error: (msg) => messageFn('error', msg)
}

const notifyFn = (type, msg, title = '系统提示') => {
  Notification({
    title,
    message: msg,
    type,
    offset: 52,
    customClass: 'my_notify_info'
  })
}
export const notify = {
  warning: (msg, title) => notifyFn('warning', msg, title),
  success: (msg, title) => notifyFn('success', msg, title),
  error: (msg, title) => notifyFn('error', msg, title)
}

// 判断是否是函数
export const isFn = (target) => (typeof target === 'function')

// 确认弹框
export const confirm = (config) => {
  const success = isFn(config) ? config : config.success
  const warning = config.warning || '该操作不可修改, 请谨慎操作!'
  const catchCd = config.catch || (() => { })
  const title = config.title || '系统提示'
  MessageBox.confirm(warning, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: 'message-confirm',
    type: 'warning'
  }).then(success?.bind(this))
    .catch(catchCd?.bind(this))
}

// 导出excel
export function ExportExcel (config) {
  try {
    const instance = new ElMapExportTable(config.exportExcelConfig)
    instance.download(config.fileName || '导出Excel')
    myMessage.success(config.successMsg || '导出Excel成功')
    config.successCallback && config.successCallback()
  } catch (error) {
    console.log(error)
    myMessage.error(config.error || '导出失败')
    config.errorCallback && config.errorCallback(error)
  }
}
// 快捷设置excel行高
ExportExcel.setRowStyle = (headerHeight = 30, mainHeight = 40) => {
  return ({ data, columnIndex, rowIndex, type }) => {
    if (type === 'header') {
      return {
        height: headerHeight
      }
    }
    if (type === 'main') {
      return {
        height: mainHeight
      }
    }
  }
}
// 设置excel列的宽和文字样式
/**
 * @param {Object} columnConfig {列索引 : 样式}
 */
ExportExcel.setColumnStyle = (columnConfig) => {
  return ({ columnIndex }) => {
    if (columnConfig[columnIndex]) {
      return columnConfig[columnIndex]
    } else if (columnConfig.default) {
      return columnConfig.default
    }
  }
}

// 获取浏览器类型
export function getCurrentBrowser () {
  const ua = navigator.userAgent.toLocaleLowerCase()
  let browserType = null
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE'
  } else if (ua.match(/firefox/) != null) {
    browserType = 'Firefox'
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = 'UC'
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = 'Opera'
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'Baidu'
  } else if (ua.match(/metasr/) != null) {
    browserType = 'Sougou'
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQ'
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'Maxthon'
  } else if (ua.match(/chrome/) != null) {
    var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    if (is360) {
      browserType = '360浏览器'
    } else if (ua.match(/edg/) != null) {
      browserType = 'Edge'
    } else {
      browserType = 'Chrome'
    }
  } else if (ua.match(/safari/) != null) {
    browserType = 'Safari'
  } else {
    browserType = 'Others'
  }
  return browserType
}
// 获取操作系统
export function getOs () {
  const ua = navigator.userAgent.toLocaleLowerCase()
  // const { platform } = navigator.userAgentData
  // if (platform) return platform
  if (ua.indexOf('windows') !== -1) return 'windows'
  if (ua.indexOf('mac os') !== -1) return 'Mac'
  if (ua.indexOf('android') > -1) return 'Android'
  if (ua.indexOf('iphone') > -1) return 'iPhone'
  if (ua.indexOf('symbianos') > -1) return 'SymbianOS'
  if (ua.indexOf('windows phone') > -1) return 'Windows Phone'
  if (ua.indexOf('ipad') > -1) return 'iPad'
  if (ua.indexOf('ipod') > -1) return 'iPod'
  return 'others'
}
export function getBrowserVersion () {
  const ua = navigator.userAgent
  let tem
  let match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(match[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return `IE ${tem[1] || ''}`
  }
  if (match[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) match.splice(1, 1, tem[1])
  return match.join(': ')
}
export function getOsVersion () {
  const userAgent = navigator.userAgent
  const isWindows = /Windows/.test(userAgent)
  const isMacOS = /Mac OS X/.test(userAgent)
  const isAndroid = /Android/.test(userAgent)
  const isiOS = /(iPhone|iPad|iPod)/.test(userAgent)

  if (isWindows) {
    // 解析Windows版本号
    const winVersion = userAgent.match(/Windows NT (\d+.\d+)/)[1]
    return `Windows ${winVersion}`
  } else if (isMacOS) {
    // 解析MacOS版本号
    const macVersion = userAgent.match(/Mac OS X (\d+.\d+)/)[1]
    return `MacOS ${macVersion}`
  } else if (isAndroid) {
    // 解析Android版本号
    const androidVersion = userAgent.match(/Android (\d+.\d+)/)[1]
    return `Android ${androidVersion}`
  } else if (isiOS) {
    // 解析iOS版本号
    let iosVersion = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)
    iosVersion = [parseInt(iosVersion[1], 10), parseInt(iosVersion[2], 10), parseInt(iosVersion[3] || 0, 10)].join('.')
    return `iOS ${iosVersion}`
  } else {
    return ''
  }
}

function _mime (option, value) {
  var mimeTypes = navigator.mimeTypes
  for (var mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true
    }
  }
  return false
}

// 筛选的结束时间
export const endTime = new Date().getFullYear() + 1

// 判断数字
export const isNumber = (val) => (typeof val === 'number')

// 埋点统计
export const count = async (config) => {
  try {
    const name = typeof config === 'string' ? config : config.name
    const itemId = config.itemId || ''
    let imei = localStorage.getItem('imei')
    if (!imei) {
      imei = uuid() + '_' + new Date().getTime()
      localStorage.setItem('imei', imei)
    }
    // itemId
    const data = {
      imei,
      pageName: name
    }
    if (itemId) {
      data.itemId = itemId
    }
    const res = await userCount(data)
    config.successCallback && config.successCallback(res)
  } catch (error) {
    console.log(error)
    config.errorCallback && config.errorCallback(error)
  }
}

export const isDataType = (target, type) => {
  const dataType = type.slice(0, 1).toUpperCase() + type.slice(1).toLowerCase()
  return Object.prototype.toString.call(target) === `[object ${dataType}]`
}

// ws sessionId
export const getSessionId = () => uuid() + new Date().getTime()

// 活动弹窗时间范围
const posterStartTime = new Date('2024-08-27 09:00:00').getTime()
const posterEndTime = new Date('2024-09-13 18:00:00').getTime()
export const showPoster = new Date().getTime() < posterEndTime && new Date().getTime() > posterStartTime

export const mdToHtml = (value) => {
  if (!value) return ''
  return marked.parse(value)
}
